import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from '@auth0/auth0-angular'
import { maintenanceGuard } from './core/guards/maintenance.guard'
import { userInitGuard } from './core/guards/user-init.guard'

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    canActivate: [maintenanceGuard, userInitGuard],
    data: {
      module: 'home',
    },
  },
  {
    path: 'equipment/details',
    loadChildren: () => import('./pages/details/details.module').then(m => m.DetailsModule),
    canActivate: [maintenanceGuard, userInitGuard],
    data: {
      module: 'details',
    },
  },
  {
    path: 'equipment/search',
    loadChildren: () => import('./pages/search/search-page.module').then(m => m.SearchPageModule),
    canActivate: [maintenanceGuard, userInitGuard],
    data: {
      module: 'search',
    },
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin-page.module').then(m => m.AdminModule),
    canActivate: [maintenanceGuard, AuthGuard],
    data: {
      module: 'admin',
    },
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./pages/maintenance/maintenance.module').then(m => m.MaintenanceModule),
    canActivate: [maintenanceGuard, userInitGuard],
    data: {
      module: 'maintenance',
    },
  },
  {
    path: '**',
    loadChildren: () => import('./pages/page404/page404.module').then(m => m.Page404Module),
    canActivate: [maintenanceGuard, userInitGuard],
    data: {
      module: 'page404',
    },
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
