import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core'
import { EventService } from '../../../core/services/event.service'
import { LogService } from 'src/app/core/services/log.service'
import { ConfigSection } from 'src/app/core/services/config.service'
import { MediaLibraryService } from 'src/app/core/services/media-library.service'
import { FormControl, ValidatorFn, Validators } from '@angular/forms'

@Component({
  template: '',
})
export abstract class DataInputComponent implements OnInit, OnChanges {
  @Input() data!: any
  @Input() key!: string
  @Input() label!: string
  @Input() default: string = ''
  @Input() configSection?: ConfigSection
  @Input() pattern: string | RegExp = '.*'

  defaultValue!: string

  inputValidators: ValidatorFn[] = []
  control: FormControl = new FormControl('', this.inputValidators)

  constructor(
    protected eventService: EventService,
    protected logger: LogService,
    protected mediaLibraryService: MediaLibraryService,
    protected cd: ChangeDetectorRef,
  ) {
    this.cd = cd
    this.mediaLibraryService = mediaLibraryService
    this.logger = logger
  }

  ngOnInit(): void {
    this.defaultValue = this.data?.[this.key] ?? this.default
    this.control.setValue(this.defaultValue)

    if (this.pattern) {
      this.inputValidators.push(Validators.pattern(this.pattern))
    }

    this.control.setValidators(this.inputValidators)
  }

  ngOnChanges(): void {
    this.control.setValue(this.data?.[this.key] ?? this.default)
  }

  abstract getValue(): string

  updateDataValue(value: any): void {
    this.data[this.key] = value
    this.eventService.notifyConfigUpdated(this.configSection)
  }
}
