import { inject } from '@angular/core'
import { CanActivateFn } from '@angular/router'
import { LogService } from '../services/log.service'
import { AppInitService, AppInitStatus } from '../services/app-init.service'
import { HistoryService } from '../services/history.service'
import { InitDataService } from '../services/init-data.service'
import { KeysService } from '../services/keys.service'
import { CurrencyService } from '../services/currency.service'
import { ConfigService } from '../services/config.service'
import { ConfigVersionService } from '../services/config-version.service'
import { ThemeService } from '../services/theme.service'
import { ClientService } from '../services/client.service'

export const userInitGuard: CanActivateFn = async () => {
  const appInitService = inject(AppInitService)
  const logger = inject(LogService)
  const historyService = inject(HistoryService)
  const currencyService = inject(CurrencyService)
  const themeService = inject(ThemeService)

  if (appInitService.status() === AppInitStatus.Success) {
    logger.debug('userInitGuard already initialized')
    return Promise.resolve(true)
  }

  appInitService.status.set(AppInitStatus.Loading)

  let continueAfterInit = false
  if (window.parent !== window) {
    await initEmbeddedMode().then((success: boolean) => {
      continueAfterInit = success
    })
  } else {
    await initNormalMode().then((success: boolean) => {
      continueAfterInit = success
    })
  }

  if (!continueAfterInit) {
    return Promise.resolve(true)
  }

  // shared inits
  currencyService.initSelectedCurrency()
  historyService.init()
  themeService.init()
  appInitService.status.set(AppInitStatus.Success)
  return Promise.resolve(true)
}

async function initEmbeddedMode(): Promise<boolean> {
  const logger = inject(LogService)
  const initDataService = inject(InitDataService)
  const keysService = inject(KeysService)
  const currencyService = inject(CurrencyService)

  logger.debug('Running userInitGuard in embedded mode')
  initDataService.initForEmbeddedUser()
  keysService.loadForEmbeddedUser()
  currencyService.initCurrenciesForEmbedded()
  return Promise.resolve(true)
}

async function initNormalMode(): Promise<boolean> {
  const logger = inject(LogService)
  const appInitService = inject(AppInitService)
  const initDataService = inject(InitDataService)
  const keysService = inject(KeysService)
  const currencyService = inject(CurrencyService)
  const configService = inject(ConfigService)
  const clientService = inject(ClientService)
  const versionService = inject(ConfigVersionService)

  logger.debug('Running userInitGuard in normal mode')
  const results = await Promise.allSettled([
    initDataService.initForUser(),
    keysService.loadForUser(),
    currencyService.loadRates(),
  ])
  if (results.some(r => r.status === 'rejected')) {
    logger.fatal('Unable to load required services. ', results)
    appInitService.status.set(AppInitStatus.Error)
    return Promise.resolve(false)
  }
  versionService.tryUpdate(configService.config)
  if (!clientService.client().webshop_is_public) {
    appInitService.status.set(AppInitStatus.NotPublic)
    return Promise.resolve(false)
  }
  currencyService.loadCurrencies()

  return Promise.resolve(true)
}
