import { CurrencyCode } from '../interfaces/currency.interface'
import { IClient } from '../services/client.service'
import { IComponentConfig, IConfig, IConfigMetadata, IPageConfig } from '../services/config.service'
import { IApiAllConfigsResponse } from '../services/init-data.service'
import { IKeys } from '../services/keys.service'
import { ImageSizes } from '../services/media-library.service'
import { currentConfigVersion } from '../versions/processors'


const headerConfig: IComponentConfig = {
  enabled: true,
  template: 'basic',
  data: {
    title: 'Rouse Services',
    styles: {
      title: {}
    },
    components: {},
    logo: {
      display: true,
      src_data: {
        kb: 0,
        name: '',
        updated: '',
        updatedFormatted: '',
        width: 0,
        height: 0,
        urls: {
          full: '',
          thumbnail: '',
          unsized: '',
          [ImageSizes.Favicon]: '',
          [ImageSizes.Thumbnail]: '',
          [ImageSizes.DefaultProductImage]: '',
          [ImageSizes.SocialMediaShare]: '',
          [ImageSizes.HeroBannerAspectRatio]: '',
          [ImageSizes.HeroBannerSmall]: '',
          [ImageSizes.HeroBannerMedium]: '',
          [ImageSizes.HeroBannerLarge]: '',
        }
      },
      src: 'https://catalog-assets.rousesales.com/_default/logos/rouse-rbds.png',
    }
  },
}

const footerConfig: IComponentConfig = {
  enabled: true,
  template: 'basic',
  data: {
    address: '',
    address_line_2: '',
    phone: '',
    email: '',
    instagram: '',
    facebook: '',
    twitter: '',
    linkedin: '',
    youtube: '',
    styles: {},
    components: {},
  },
}

const legalTermsConfig: IComponentConfig = {
  enabled: true,
  template: 'basic',
  data: {
    terms: 'this thing is different from the other thing',
    styles: {},
    components: {},
  },
}

const contactFormConfig: IComponentConfig = {
  enabled: true,
  template: 'basic',
  data: {
    styles: {},
    components: {
      assetCard: {
        enabled: true,
        template: 'contact-form',
        data: {
          styles: {},
          components: {
            badgesContainer: {
              enabled: false,
              template: 'basic',
              data: {
                styles: {},
                components: {},
              }
            }
          },
        }
      }
    },
  },
}

const homeConfig: IPageConfig = {
  seo: {
    data: {
      title: 'Home',
      description: '<siteDescription>',
    },
  },
  components: {
    browseByCategory: {
      enabled: true,
      template: 'basic',
      data: {
        styles: {},
        components: {},
        quantity: 5,
      }
    },
    heroBanner: {
      enabled: true,
      template: 'basic',
      data: {
        mainText: 'Browse Equipment that Meet Your Needs',
        mainTextSize: 'h3',
        subText: 'Search our large inventory of quality used machines',
        imageSrc: 'https://picsum.photos/1400/500',
        imageSrcData: {
          height: 0,
          width: 0,
          kb: 0,
          name: '',
          updated: '',
          updatedFormatted: '',
          urls: {
            [ImageSizes.SocialMediaShare]: '',
          }
        },
        styles: {
          container: {
            'background-color': '#e3e3e3',
          },
          mainText: {
            color: '#ffffff',
          },
          subText: {
            color: '#ffffff',
          },
        },
        components: {
          search: {
            enabled: true,
            template: 'basic',
            data: {
              styles: {},
              components: {},
            }
          },
        },
      }
    },
    contactFormSection: {
      enabled: true,
      template: 'basic',
      data: {
        styles: {},
        components: {},
      }
    },
    featuredCarousel: {
      enabled: true,
      template: 'basic',
      data: {
        styles: {},
        components: {
          assetCard: {
            enabled: true,
            template: 'basic',
            data: {
              styles: {},
              components: {
                badgesContainer: {
                  enabled: true,
                  template: 'basic',
                  data: {
                    styles: {},
                    components: {
                      badge: {
                        enabled: true,
                        template: 'basic',
                        data: {
                          styles: {},
                          components: {},
                        }
                      }
                    },
                  }
                }
              },
            }
          }
        },
      }
    },
  }
}

const searchConfig: IPageConfig = {
  seo: {
    data: {
      title: 'Used Commercial Tools & Equipment For Sale',
      description: '<siteDescription>',
    },
  },
  components: {
    search: {
      enabled: true,
      template: 'basic',
      data: {
        styles: {},
        components: {},
      }
    },
    sort: {
      enabled: true,
      template: 'basic',
      data: {
        styles: {},
        components: {},
      }
    },
    searchGrid: {
      enabled: true,
      template: 'basic',
      data: {
        styles: {},
        components: {
          assetCard: {
            enabled: true,
            template: 'basic',
            data: {
              enableButtons: false,
              styles: {},
              components: {
                badgesContainer: {
                  enabled: true,
                  template: 'basic',
                  data: {
                    styles: {},
                    components: {
                      badge: {
                        enabled: true,
                        template: 'basic',
                        data: {
                          styles: {},
                          components: {},
                        }
                      }
                    },
                  }
                }
              },
            }
          }
        },
      }
    },
    filterContainer: {
      enabled: true,
      template: 'basic',
      data: {
        styles: {},
        components: {},
      }
    },
  }
}

const detailsConfig: IPageConfig = {
  seo: {
    data: {
      title: '<year> <make> <model>',
      description: '<siteDescription>',
    }
  },
  components: {
    shareButton: {
      enabled: true,
      template: 'basic',
      data: {
        styles: {},
        components: {},
      }
    },
    leadForm: {
      enabled: true,
      template: 'basic',
      data: {
        styles: {},
        components: {},
      }
    },
    quickLinksBanner: {
      enabled: true,
      template: 'basic',
      data: {
        styles: {},
        components: {},
      }
    },
    similarCarousel: {
      enabled: false,
      template: 'basic',
      data: {
        styles: {},
        components: {
          assetCard: {
            enabled: true,
            template: 'basic',
            data: {
              styles: {},
              components: {
                badgesContainer: {
                  enabled: true,
                  template: 'basic',
                  data: {
                    styles: {},
                    components: {
                      badge: {
                        enabled: true,
                        template: 'basic',
                        data: {
                          styles: {},
                          components: {},
                        }
                      }
                    },
                  }
                }
              },
            }
          }
        },
      }
    },
    recentlyViewedCarousel: {
      enabled: false,
      template: 'basic',
      data: {
        styles: {},
        components: {
          assetCard: {
            enabled: true,
            template: 'basic',
            data: {
              styles: {},
              components: {
                badgesContainer: {
                  enabled: true,
                  template: 'basic',
                  data: {
                    styles: {},
                    components: {
                      badge: {
                        enabled: true,
                        template: 'basic',
                        data: {
                          styles: {},
                          components: {},
                        }
                      }
                    },
                  }
                }
              },
            }
          }
        },
      }
    },
    assetDetailsHeader: {
      enabled: true,
      template: 'basic',
      data: {
        styles: {},
        components: {},
      }
    },
    assetInformation: {
      enabled: true,
      template: 'basic',
      data: {
        showConfiguration: true,
        styles: {},
        components: {},
      }
    },
  },
}

export const mockConfigData: IConfig = {
  version: currentConfigVersion,
  sitemap: {
    static: [],
    dynamic: [],
  },
  seo: {
    data: {
      title: '',
      description: '',
      favicon_img_data: {
        height: 0,
        width: 0,
        kb: 0,
        name: '',
        originalFileName: '',
        updated: '',
        updatedFormatted: '',
        urls: {
          [ImageSizes.Favicon]: '',
        }
      },
      social_img_data: {
        height: 0,
        width: 0,
        kb: 0,
        name: '',
        originalFileName: '',
        updated: '',
        updatedFormatted: '',
        urls: {
          [ImageSizes.SocialMediaShare]: '',
        }
      },
    }
  },
  general: {
    data: {
      default_img_data: {
        height: 0,
        width: 0,
        kb: 0,
        name: '',
        originalFileName: '',
        updated: '',
        updatedFormatted: '',
        urls: {
          [ImageSizes.Favicon]: '',
        }
      },
    }
  },
  currency: {
    available: [CurrencyCode.USD, CurrencyCode.CAD],
    default: CurrencyCode.USD,
    enabled: true,
    template: 'basic',
    data: {
      styles: {},
      components: {},
    },
  },
  theme: {
    id: 'dark-theme',
    fonts: {},
    overrides: {
      'font-color': 'green',
      'heading-font-color': 'blue',
    },
  },
  header: headerConfig,
  footer: footerConfig,
  legalTerms: legalTermsConfig,
  contactForm: contactFormConfig,
  page: {
    home: homeConfig,
    search: searchConfig,
    details: detailsConfig,
  }
}

export const mockClientData: IClient = {
  id: 123,
  code: 'MOCK',
  client_code: 'MOCK',
  sales_code: 'MOCK',
  name: 'Mock Client',
  domain: 'mock-client.webshop.develop.rouseservices.com',
  domain_status_request_id: 'mock-client.webshop.develop.rouseservices.com-1234567890',
  channel_webshop_selling_contact_email: 'mock@mock.com',
  channel_webshop_selling_contact_form_submission_email: 'mock_sub@mock.com',
  channel_webshop_selling_contact_phone: '1234567890',
  email_client_contact: '',
  email_client_logo: undefined,
  email_client_logo_data: undefined,
  webshop_is_public: true,
  webshop_unit_of_distance: 'miles',
}

export const mockKeysData: IKeys = {
  heap_key: '3762971474',
  recaptcha_site_key: 'test-key',
  google_webfont_key: 'test-key',
  google_tag_manager: 'test-key',
  recaptcha_secret_key: true,
  google_analytics: [
    {
      name: 'mock google analytics',
      id: 'mock-google-analytics-id',
    },
    {
      name: 'mock google analytics 2',
      id: 'mock-google-analytics-id-2',
    }
  ],
}

export const mockConfigMetadata: IConfigMetadata = {
  id: 0,
  is_live: false,
  name: 'Mock Config',
  date_modified: '2023-09-20 19:09:16',
}

export const allConfigs: IApiAllConfigsResponse = {
  client: mockClientData,
  results: [{
    ...mockConfigMetadata,
    id: 1,
    name: 'Mock Config 1',
    config: {
      ...mockConfigData,
    }
  }, {
    ...mockConfigMetadata,
    id: 2,
    name: 'Mock Config 2',
    is_live: true,
    config: {
      ...mockConfigData,
    }
  }, {
    ...mockConfigMetadata,
    id: 3,
    name: 'Mock Config 3',
    config: {
      ...mockConfigData,
    }
  }]
}
