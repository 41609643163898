import { DestroyRef, Injectable, inject } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { LogService } from './log.service'
import { AdminConfirmDialogComponent } from 'src/app/admin/dialog/confirm/admin-confirm-dialog.component'
import { ConfirmDialogComponent } from 'src/app/components/dialog/confirm/confirm-dialog.component'
import { AdminInputDialogComponent } from 'src/app/admin/dialog/input/admin-input-dialog.component'
import { EventService } from './event.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { ContactDialogComponent } from 'src/app/components/dialog/contact/contact-dialog.component'
import { IAsset } from '../interfaces/asset.interface'
import { MediaLibraryDialogComponent } from 'src/app/components/dialog/media-library/media-library.component'


export interface IConfirmDialogOptions {
  title?: string
  content?: string
  cancelLabel?: string
  confirmLabel?: string
}

export interface IInputDialogOptions {
  title?: string
  inputs: IInputDialogInputOptions[]
  cancelLabel?: string
  confirmLabel?: string
  validate?: () => boolean
}

export interface IInputDialogInputOptions {
  type: 'text'
  label?: string
  defaultValue: string | number
  change: ($event: any) => void
  selectedOnOpen?: boolean
}

export interface IContactDialogOptions {
  type: '' | 'asset'
  title?: string
  asset?: IAsset
  panelClass?: string
}

export interface IMediaLibraryDialogOptions {
  type: 'media-library'
  mode?: 'select' | 'manage'
  files?: string[]
  title?: string
  panelClass?: string
  onSelect?: ($event: any) => void
}

export interface IFiltersDialogOptions {
  title?: string
  panelClass?: string
}

@Injectable()
export class DialogService {
  private _destroyRef = inject(DestroyRef)

  constructor(
    public dialog: MatDialog,
    public logger: LogService,
    private _eventService: EventService,
  ) {}

  init(): void {
    this._eventService.contactClicked$.pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(asset => {
      this._contact({
        type: asset ? 'asset' : '',
        title: asset ? 'Contact Sales' : 'Contact Us',
        asset,
      })
    })
  }

  private _confirm(
    confirmOptions: IConfirmDialogOptions,
    dialogComponent: typeof AdminConfirmDialogComponent | typeof ConfirmDialogComponent,
  ): MatDialogRef<AdminConfirmDialogComponent | ConfirmDialogComponent, any> {
    const {
      title = null,
      content = null,
      cancelLabel = 'Cancel',
      confirmLabel = 'Confirm',
    } = confirmOptions
    return this.dialog.open(dialogComponent, {
      data: {
        title,
        content,
        cancelLabel,
        confirmLabel,
      },
    })
  }

  confirm(confirmOptions: IConfirmDialogOptions): MatDialogRef<ConfirmDialogComponent, any> {
    return this._confirm(confirmOptions, ConfirmDialogComponent)
  }

  confirmAdmin(confirmOptions: IConfirmDialogOptions): MatDialogRef<AdminConfirmDialogComponent, any> {
    return this._confirm(confirmOptions, AdminConfirmDialogComponent)
  }

  private _mediaLibrary(
    mediaLibraryOptions: IMediaLibraryDialogOptions,
  ): MatDialogRef<MediaLibraryDialogComponent, any> {
    const {
      type = '',
      title = 'Media Library',
      panelClass = 'media-library-dialog',
      mode = 'select',
      onSelect = () => {},
    } = mediaLibraryOptions
    this.dialog.closeAll()
    return this.dialog.open(MediaLibraryDialogComponent, {
      data: {
        type,
        title,
        mode,
        onSelect: onSelect,
      },
      panelClass,
      autoFocus: 'input',
    })
  }

  mediaLibrary(mediaLibraryOptions: IMediaLibraryDialogOptions): MatDialogRef<MediaLibraryDialogComponent, any> {
    return this._mediaLibrary(mediaLibraryOptions)
  }

  private _input(
    inputOptions: IInputDialogOptions,
    dialogComponent: typeof AdminInputDialogComponent,
  ): MatDialogRef<AdminInputDialogComponent, any> {
    const {
      title = null,
      inputs = [],
      cancelLabel = 'Cancel',
      confirmLabel = 'Submit',
      validate = () => true,
    } = inputOptions
    return this.dialog.open(dialogComponent, {
      data: {
        title,
        inputs,
        cancelLabel,
        confirmLabel,
        validate,
      }
    })
  }

  inputAdmin(inputOptions: IInputDialogOptions): MatDialogRef<AdminInputDialogComponent, any> {
    return this._input(inputOptions, AdminInputDialogComponent)
  }

  private _contact(
    contactOptions: IContactDialogOptions,
  ): MatDialogRef<ContactDialogComponent, any> {
    const {
      type = '',
      title = 'Contact Us',
      asset = null,
      panelClass = 'contact-dialog',
    } = contactOptions
    return this.dialog.open(ContactDialogComponent, {
      data: {
        type,
        title,
        asset,
      },
      panelClass,
      autoFocus: 'input',
    })
  }
}
