export enum CurrencyCode {
  USD = 'USD',
  CAD = 'CAD',
  MXN = 'MXN',
  EUR = 'EUR',
  JPY = 'JPY',
  AUD = 'AUD',
  CNY = 'CNY',
  KRW = 'KRW',
}

export interface ICurrency {
  name: string
  code: CurrencyCode
  symbol: string
  rate?: number
}

export interface ICurrencyRateData {
  currency: CurrencyCode
  rate: number
}
