import { Injectable } from '@angular/core'
import { LogService } from './log.service'
import { environment } from 'src/environments/environment'


export interface IEnvironment {
  production: boolean
  useMock: boolean
  useMockAdmin: boolean
  auth: {
    domain: string
    clientId: string
    authorizationParams: {
      redirect_uri: any
    },
  },
  client: string
  data: {
    api: string
  },
  image: {
    api: string
    legacyApi: string
  },
  logLevel: number,
  clientsBlockRobots: string[],
  googleAnalytics: {
    trackingId: string
  }
}

@Injectable()
export class EnvironmentService {

  private _environment!: IEnvironment

  constructor(
    private logger: LogService,
  ) {
    this._environment = environment
    logger.debug('Environment file set to: ', this._environment)
  }

  get(): IEnvironment {
    return this._environment
  }

}
