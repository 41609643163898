// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  // Use mock data instead of live data from the api
  useMock: false,
  // Use the admin with mock data, expirimental
  useMockAdmin: false,
  auth: {
    domain: 'login.rouseservices.com',
    clientId: '1ELfP1AQiFPVA2ct8Cqfa2wN2ige3PtM',
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: 'https://api.rouseservices.com',
    },
  },
  client: '',
  data: {
    api: '/api',
  },
  image: {
    api: "https://media.rouseservices.com",
    legacyApi: "https://imageserver.rouseservices.com/ImageProcessor/get/getimage.aspx"
  },
  /**
   * Log Levels:
   * All = 0,
   * Debug = 1,
   * Info = 2,
   * Warn = 3,
   * Error = 4,
   * Fatal = 5,
   * Off = 6
   */
  logLevel: 4,
  recaptcha: {
    siteKey: '6LflRAMpAAAAAOIIzfexlwMtoJTW_z6OnXTln2MZ',
  },
  sentry: {
    environment: 'prod',
    traceSampleRate: 0.05,
  },
  clientsBlockRobots: ['xyz', 'etc', 'wdl'],
  firebaseEnv: 'prod',
  firebaseConfig: {
    apiKey: "AIzaSyAbtDDkRn6v6x6FpoETPxkbqxVN9E9rAH8",
    authDomain: "rfm-prod-5f68c8.firebaseapp.com",
    databaseURL: "https://rfm-prod-5f68c8.firebaseio.com",
    projectId: "rfm-prod-5f68c8",
    storageBucket: "rfm-prod-5f68c8.appspot.com",
    messagingSenderId: "635201224585",
    appId: "1:635201224585:web:f8cb2b19b82a7eda0a2b58",
    measurementId: "G-2JDXPBX681"
},
googleAnalytics: {
  trackingId: 'G-SSGC4V29KM',
},
}
