import { Injectable } from '@angular/core'
import { Observable, delay, of } from 'rxjs'
import { MockService } from './mock.service'
import { environment } from 'src/environments/environment'
import { ApiRequestService, IPagination } from './api-request.service'
import { FetchService } from './fetch.service'
import { LogService } from './log.service'
import { IAsset, IAssetCategoriesResponse, IAssetCategory, IAssetsResponse } from '../interfaces/asset.interface'
import { HttpErrorResponse } from '@angular/common/http'
import { FilterService } from './filter.service'
import { IJsonLogic } from './json-logic.service'
import { ClientService } from './client.service'


export interface IGetAssetsOptions {
  pagination?: IPagination,
  fields?: Array<keyof IAsset>,
  filters?: IJsonLogic,
  queryParams?: { [key: string]: string }
}

@Injectable()
export class AssetService {
  constructor(
    private mockService: MockService,
    private fetchService: FetchService,
    private apiRequestService: ApiRequestService,
    private logger: LogService,
    private filterService: FilterService,
    private clientService: ClientService,
  ) {}

  assetKeysForApi: (keyof IAsset)[] = [
    'equipment_number',
    'serial_number',
    'detailed_description',
    'listing_date',
    'list_price',
    'list_price_native',
    'list_price_rfx_date',
    'list_price_native_currency',
    'currency_type',
    'compare_at_price_multiplier',
    'lowest_monthly_payment',
    'lowest_monthly_payment_rfx_date',
    'filter_category',
    'filter_sub_category',
    'branch_city',
    'branch_state',
    'year',
    'make',
    'model',
    'meter_hours',
    'meter_miles_precise',
    'is_featured',
    'is_warranty_eligible',
    'is_popular',
    'is_low_hours',
    'is_financing_eligible',
    'is_just_listed',
    'image',
  ]

  getCategories(pagination: IPagination, fields: Array<keyof IAssetCategory>): Observable<IAssetCategoriesResponse> {
    this.logger.debug('AssetService.getCategories() pagination: ', pagination, ' fields: ', fields)
    if (environment.useMock) {
      return of(this.mockService.getAssetCategories(pagination)).pipe(delay(500))
    }

    return this.fetchService.postRequest<IAssetCategoriesResponse>(`${this.apiRequestService.buildEndpoint('category')}`, {
      filters: {},
      pagination,
      fields,
    }) as Observable<IAssetCategoriesResponse>
  }

  getAsset(equipmentNumber: string): Observable<IAsset | HttpErrorResponse> {
    this.logger.debug('AssetService.getAsset() equipmentNumber: ', equipmentNumber)
    if (environment.useMock) {
      return of(this.mockService.getAssets().data.find(asset => asset.equipment_number === equipmentNumber) as IAsset).pipe(delay(500))
    }
    const endpoint = this.apiRequestService.buildEndpoint(`equipment/${encodeURIComponent(equipmentNumber)}`, {
      meter_uod: this.clientService.getMeterUod(),
    })
    return this.fetchService.getRequest<IAsset>(endpoint)
  }

  getAssets(options: IGetAssetsOptions): Observable<IAssetsResponse | HttpErrorResponse> {
    const {
      pagination = {
        direction: 'asc',
        field: 'equipment_number',
        index: 0,
        size: 10,
      },
      fields = this.assetKeysForApi,
      filters = {},
      queryParams = {}
    } = options
    this.logger.debug('AssetService.getAssets() pagination: ', pagination)
    if (environment.useMock) {
      return of(this.mockService.getAssets(pagination)).pipe(delay(500))
    }
    queryParams.meter_uod = this.clientService.getMeterUod()

    return this.fetchService.postRequest<IAssetsResponse>(`${this.apiRequestService.buildEndpoint('equipment', queryParams)}`, {
      filters,
      pagination,
      fields,
    }) as Observable<IAssetsResponse>
  }

  getFeaturedAssets(total: number): Observable<IAssetsResponse | HttpErrorResponse> {
    this.logger.debug('AssetService.getFeaturedAssets() total: ', total)
    return this.getAssets({
      pagination: {
        direction: 'asc',
        field: 'equipment_number',
        index: 0,
        size: total,
      },
      filters: this.filterService.build(({ and, is, has }) => and(
        is.listed,
        is.featured,
        has.price,
      )),
    })
  }

  getSimilarAssets(equipmentNumber: string, total: number): Observable<IAssetsResponse | HttpErrorResponse> {
    this.logger.debug('AssetService.getSimilarAssets() total: ', total, ' equipmentNumber: ', equipmentNumber)
    if (environment.useMock) {
      return of(this.mockService.getAssets({
        direction: 'asc',
        field: 'equipment_number',
        index: 0,
        size: total,
      })).pipe(delay(500))
    }
    const endpoint = this.apiRequestService.buildEndpoint(`equipment/similar/${encodeURIComponent(equipmentNumber)}`, {
      count: `${total}`,
      meter_uod: this.clientService.getMeterUod(),
    })
    return this.fetchService.getRequest<IAssetsResponse>(endpoint)
  }

  getAssetsById(total: number, assetIds: string[]): Observable<IAssetsResponse | HttpErrorResponse> {
    this.logger.debug('AssetService.getAssetsById() total: ', total, ' assetIds: ', assetIds)
    return this.getAssets({
      pagination: {
        direction: 'asc',
        field: 'equipment_number',
        index: 0,
        size: 10,
      },
      filters: this.filterService.build(({ and, is, or, inArray, variable }) => and(
        is.listed,
        or(
          inArray(
            variable('equipment_number'),
            [...assetIds]
          ),
          inArray(
            variable('display_equipment_number'),
            [...assetIds]
          ),
        )
      )),
    })
  }
}
