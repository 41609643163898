/* eslint-disable @typescript-eslint/unbound-method */
// Disabling the linting on this file due to a known issue: https://github.com/typescript-eslint/typescript-eslint/issues/1929
import { Component, DestroyRef, EventEmitter, OnInit, Output, ViewChild, inject, input } from '@angular/core'
import { FormControl, FormGroup, FormGroupDirective, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms'
import { FormService } from 'src/app/core/services/form.service'
import { ImageService, LegacyImageProcessorTypes } from 'src/app/core/services/image.service'
import { LogService } from 'src/app/core/services/log.service'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatIconModule } from '@angular/material/icon'
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { CommonModule } from '@angular/common'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { KeysService } from 'src/app/core/services/keys.service'
import { ToastService } from 'src/app/core/services/toast.service'
import { ContactDialogComponent } from '../dialog/contact/contact-dialog.component'
import { ButtonModule } from '../button/button.module'
import { Subject } from 'rxjs'
import { IAsset } from 'src/app/core/interfaces/asset.interface'
import { NgxMaskDirective } from 'ngx-mask'
import { ReCaptchaService } from 'src/app/core/services/recaptcha.service'
import { CurrencyService } from 'src/app/core/services/currency.service'
import { NgxCurrencyDirective } from 'ngx-currency'


@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styles: [`
    @use "sass:map";
    @import 'src/assets/mixins';
    @import 'src/assets/colors';

    .ContactForm {
      margin: 0 auto;
      width: 100%;
      text-align: right;

      @include mq(medium) {
        max-width: 488px;
      }

      .grid-container {
        padding-left: 0;
        padding-right: 0;
      }

      mat-form-field {
        width: 100%;

        &.mat-form-field-invalid {
          margin-bottom: calc(var(--gutter) / 2);
        }

        input {
          line-height: 1.6rem;
        }
      }

      mat-label {
        line-height: 1.15rem;
      }

      &__form {
        padding: 5px 0 0 0;
      }

      .offer {
        margin-bottom: 12px;
      }
    }
  `],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatDialogModule,
    MatInputModule,
    ButtonModule,
    NgxMaskDirective,
    NgxCurrencyDirective,
  ],
  providers: [],
})
export class ContactFormComponent implements OnInit {
  private _destroyRef = inject(DestroyRef)
  private _formService = inject(FormService)
  private _imageService = inject(ImageService)
  private _logger = inject(LogService)
  private _recaptchaService = inject(ReCaptchaService)
  private _keysService = inject(KeysService)
  private _toastService = inject(ToastService)
  protected currencyService = inject(CurrencyService)

  @ViewChild('form') formElement!: NgForm

  @Output() submitSuccess = new EventEmitter()

  withCloseButton = input(false)
  dialogRef = input<MatDialogRef<ContactDialogComponent>>()
  hideSubmitButton = input(false)
  submitEventNotifier = input<Subject<void>>()
  assetData = input<IAsset>()
  successTitle = input('Thank You!')
  successBody = input('We have received your submission and will be in touch soon!')

  contactForm!: FormGroup
  disabled: boolean = true
  submitStatus: 'idle' | 'submitting' | 'success' | 'error' = 'idle'

  ngOnInit(): void {
    if (this._keysService.keys().recaptcha_site_key && this._keysService.keys().recaptcha_site_key !== '') {
      this.disabled = false
    }
    const formControls: { [key: string]: FormControl } = {
      first_name: new FormControl({ value: '', disabled: this.disabled }, [
        Validators.required,
      ]),
      last_name: new FormControl({ value: '', disabled: this.disabled }, [
        Validators.required,
      ]),
      company_name: new FormControl({ value: '', disabled: this.disabled }, [
        Validators.required,
      ]),
      email: new FormControl({ value: '', disabled: this.disabled }, [
        Validators.required,
        Validators.email,
      ]),
      phone: new FormControl({ value: '', disabled: this.disabled }, [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(10),
      ]),
      message: new FormControl({ value: '', disabled: this.disabled }, []),
    }
    // if (this.assetData()) {
    //   formControls.offer_price_native = new FormControl({ value: null, disabled: this.disabled }, [])
    //   formControls.offer_currency = new FormControl({ value: this.currencyService.selectedCurrency().code, disabled: this.disabled }, [])
    // }
    this.contactForm = new FormGroup(formControls)
    this.submitEventNotifier()?.pipe(
      takeUntilDestroyed(this._destroyRef)
    ).subscribe(() => {
      Object.values(this.contactForm.controls).forEach(control => {
        control.markAsTouched() // causes form to validate
      })
      this.formElement.ngSubmit.emit()
    })
  }

  get firstName() { return this.contactForm.get('first_name') }
  get lastName() { return this.contactForm.get('last_name') }
  get company() { return this.contactForm.get('company_name') }
  get offer() { return this.contactForm.get('offer_price_native') }
  get email() { return this.contactForm.get('email') }
  get phone() { return this.contactForm.get('phone') }
  get message() { return this.contactForm.get('message') }

  submitContactForm(formDirective: FormGroupDirective): void {
    if (this.contactForm.invalid) { return }
    this._logger.debug('submitting contact form: ', this.contactForm.value, this.assetData)

    this._recaptchaService
      .execute('contactForm')
      .then((token: string) => {
        this._logger.debug('recaptcha token: ', token)
        this._formService.submitContactForm({
          ...this.contactForm.value,
          ...(this.assetData() ? {
            equipment_number: this.assetData()?.equipment_number,
            year: this.assetData()?.year?.toString(),
            make: this.assetData()?.make,
            model: this.assetData()?.model,
            list_price: this.assetData()?.list_price?.toString(),
            category: this.assetData()?.category ?? this.assetData()?.filter_category,
            sub_category: this.assetData()?.sub_category ?? this.assetData()?.filter_sub_category,
            branch_state: this.assetData()?.branch_state,
            branch_city: this.assetData()?.branch_city,
            branch_code: this.assetData()?.branch_zipcode,
            asset_photo: this.assetData()?.images[0] ?
              this._imageService.buildLegacyProcessorSrc({ guid: this.assetData()?.images[0], type: LegacyImageProcessorTypes.Normal })
              : null,
            serial_number: this.assetData()?.serial_number,
          } : {}),
          form_type: this.assetData() ? 'asset' : 'contact',
          page_url: window.location.href,
          token,
        }).subscribe((response) => {
          if (response.success) {
            this.contactForm.reset()
            formDirective.resetForm()
            this._logger.debug('contact form submitted successfully')
            this.submitSuccess.emit()
            this.submitStatus = 'success'
          } else {
            this._toastService.error({
              message: 'There was an error submitting your form. Please try again.',
              includeClose: true,
            })
            this._logger.error(`action: submitContactForm, response_status: ${response.success}, response_message: ${response.reason}`)
          }
        })
      })
  }
}
