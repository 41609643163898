import { Injectable, inject } from '@angular/core'
import { LogService } from './log.service'
import { IAsset } from '../interfaces/asset.interface'

type BadgeKey = 'is_featured' | 'is_warranty_eligible' | 'is_popular' | 'is_low_hours' | 'is_financing_eligible'

export interface IBadgeSetting {
  key: BadgeKey
  icon: string
  label: string
}

export const availableBadges: IBadgeSetting[] = [
  {
    key: 'is_featured',
    icon: 'sell',
    label: 'Featured',
  },
]

@Injectable()
export class BadgeService {
  private _logger: LogService = inject(LogService)
  getBadgesForData(data: IAsset | undefined): IBadgeSetting[] {
    const badges: IBadgeSetting[] = []
    if (data === undefined) {
      return badges
    }
    availableBadges
      .filter(badge => data[badge.key] === true)
      .forEach(badge => {
        badges.push(badge)
      })
    return badges
  }
}
