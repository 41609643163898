import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild, inject } from '@angular/core'
import { DataInputComponent } from '../data-input.component'
import { environment } from 'src/environments/environment'
import { CommonModule } from '@angular/common'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { DialogService } from 'src/app/core/services/dialog.service'
import { ButtonModule } from 'src/app/components/button/button.module'
import { EMPTY_MEDIA_DATA, IMediaData, ImageSizes } from 'src/app/core/services/media-library.service'
import { ToastService } from 'src/app/core/services/toast.service'

@Component({
  selector: 'app-data-file-upload',
  standalone: true,
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss', '../../../../../assets/admin.scss'],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    ButtonModule,
  ],
})
export class FileUploadDataInputComponent extends DataInputComponent implements OnInit, OnChanges {
  dialogService = inject(DialogService)
  toastService = inject(ToastService)

  @ViewChild('dataInput') dataInput!: ElementRef
  @ViewChild('fileUploadWrapper') fileUploadWrapper!: ElementRef

  @Input() accept?: string = 'image/*'
  @Input() recommendations?: string = ''
  @Input() multiple?: boolean
  @Input() file_max_size: number = 5000000
  @Input() size_error_message: string = 'File is larger than 5MB'
  @Input() mediaData?: IMediaData = EMPTY_MEDIA_DATA
  @Input() file_dimensions?: string = ''
  @Input() show_preview?: boolean = true
  @Input() show_media_library?: boolean = true

  Array = Array
  files: FileList | File[] = []
  reader: FileReader = new FileReader()
  tempFilesURLs: string[] = []
  tempFileErrors: string[] = []
  tempFileLoading: string[] = []
  isDragging: boolean = false
  ImageSizes = ImageSizes

  override ngOnInit(): void {
    this.updateFileUrl()
  }

  override ngOnChanges(): void {
    this.updateFileUrl()
  }

  private updateFileUrl() {
    if (this.mediaData && this.mediaData.name && this.mediaData.urls) {
      this.tempFilesURLs = [this.getImgThumbnail(this.mediaData.name)]
      const fileName = `${this.mediaData.name.split('/').pop() || ''}.${ImageSizes.Full}`
      const fileExt = fileName?.split('.').pop()
      const metadata = {
        type: `image/${fileExt}`
      }
      const file = new File([], this.mediaData.urls[ImageSizes.Full], metadata)
      this.files = [file]
    }
  }

  getImgThumbnail(fileName?: string): string {
    if (!fileName) {
      return ''
    }
    const fileExt = fileName.split('.').pop()
    const fileNameWithoutExt = fileName.replace(`.${fileExt}`, '')
    return `${fileNameWithoutExt}.${ImageSizes.Thumbnail}.${fileExt}`
  }

  getValue(): string {
    return this.dataInput.nativeElement.value
  }

  handleDragEnter(): void {
    this.isDragging = true
    this.cd.detectChanges()
  }

  handleDragLeave(): void {
    this.isDragging = false
    this.cd.detectChanges()
  }

  handleShowMediaLibrary(): void {
    this.dialogService.mediaLibrary({
      type: 'media-library',
      files: this.tempFilesURLs,
      onSelect: (data: any) => {
        this.updateDataValue(data)
      }
    })
  }

  handleAddClick(): void {
    if (this.show_media_library) {
      this.handleShowMediaLibrary()
      return
    }
    this.dataInput.nativeElement.click()
  }

  handleRemoveClick(index: number): void {
    const fileList = Array.from(this.files)
    fileList.splice(index, 1)
    this.files = fileList
    this.tempFilesURLs.splice(index, 1)
    this.tempFileErrors.splice(index, 1)
    this.dataInput.nativeElement.value = ''
    this.updateDataValue(null)
  }

  onFileSelected(event: Event) {
    this.logger.debug(`file selected: `, event)
    const eventTarget = event.target as HTMLInputElement
    if (typeof (FileReader) !== 'undefined') {
      this.files = eventTarget.files || []
      this.tempFilesURLs = Array.from(this.files).map((file: any) => URL.createObjectURL(file))
      const fileErrors: string[] = []

      this.Array.from(this.files).map((file: File, index: number) => {
        this.tempFileLoading[index] = 'uploading'

        if (eventTarget?.files instanceof FileList && eventTarget?.files[0]?.size > this.file_max_size) {
          this.tempFileErrors[index] = this.size_error_message
          return
        }

        this.mediaLibraryService.uploadFile(file).then((fileName) =>{
          const fileExt = fileName.split('.').pop()
          const fileNameWithoutExt = fileName.split('.').slice(index, -1).join('.')
          const file_path = `source_images/${fileNameWithoutExt}.${fileExt}`
          if (this.key) {
            this.updateDataValue(`${environment.image.api}/${file_path}`)
          }
          this.tempFileLoading[index] = ''
        })
          .catch((error) => {
            this.tempFileErrors[index] = error.userMessage
            this.logger.error('Unable to upload file.  ', error)
            fileErrors.push(file.name)
            this.toastService.error({ message: `Unable to upload file: ${fileErrors.join(', ')}`, includeClose: true, duration: 60000 })
            this.tempFileLoading[index] = ''
          })
      })
    }
  }

  removeFileHash(fileName: string = ''): string {
    const hash = fileName.match(/-\w{12}/)
    return fileName.replace((hash?.[0] || ''), '')
  }

}
