import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import * as Sentry from '@sentry/angular'
import { environment } from './environments/environment'

import { AppModule } from './app/app.module'

Sentry.init({
  dsn: 'https://8ac8ce435fe0ab0ecac7f42f17e4f476@o1157537.ingest.sentry.io/4506435130163200',
  environment: environment.sentry.environment,
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/\w{3,5}\.webshop\.(beta\.|develop\.|stage\.)?rouseservices\.com\/api/,
  ],
  // Performance Monitoring
  tracesSampleRate: environment.sentry.traceSampleRate,
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

enableProdMode()
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err))
